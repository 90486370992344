












































































import Vue from 'vue'
import SortAddConditionPopupStore from '@/store/sort/sortAddConditionPopup'
import SortGroupAddStore, { SortConditionPopularFieldType } from '@/store/sort/sortGroupAdd'
import SearchInstanceStore from '@/store/search/searchInstance'

export default Vue.extend({
  props: {
    sortOrderOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      gradation: '',
    }
  },
  computed: {
    addTrackingBasedPopupOpen: {
      get(): boolean {
        return SortAddConditionPopupStore.state.addTrackingBasedPopupOpen
      },
      set(value: boolean) {
        if (value) {
          this.gradation = ''
        }
        SortAddConditionPopupStore.commit('setAddTrackingBasedPopupOpen', value)
      },
    },
    conditionField: {
      get(): string {
        return SortAddConditionPopupStore.state.condition.field
      },
      set(field: string) {
        SortAddConditionPopupStore.commit('setConditionField', field)
      },
    },
    conditionOrder: {
      get(): string {
        return SortAddConditionPopupStore.state.condition.order
      },
      set(order: string) {
        SortAddConditionPopupStore.commit('setConditionOrder', order)
      },
    },
    popularGradation: {
      get(): string {
        return this.gradation
      },
      set(popularGradation: string) {
        this.gradation = popularGradation
      },
    },
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
    getPopularTypes() {
      return [
        {
          value: SortConditionPopularFieldType.VIEW,
          text: this.$t('sortSettings.addSortGroup.addPopularityBasedPopup.popularType.views'),
        },
        {
          value: SortConditionPopularFieldType.CLICK,
          text: this.$t('sortSettings.addSortGroup.addPopularityBasedPopup.popularType.clicks'),
        },
        {
          value: SortConditionPopularFieldType.PURCHASE,
          text: this.$t('sortSettings.addSortGroup.addPopularityBasedPopup.popularType.purchases'),
        },
      ]
    },
    getPossibleDaysGradations() {
      return [
        { value: 1, text: this.$t('sortSettings.addSortGroup.addPopularityBasedPopup.gradations.day1') },
        { value: 7, text: this.$t('sortSettings.addSortGroup.addPopularityBasedPopup.gradations.day7') },
        { value: 14, text: this.$t('sortSettings.addSortGroup.addPopularityBasedPopup.gradations.day14') },
        { value: 30, text: this.$t('sortSettings.addSortGroup.addPopularityBasedPopup.gradations.day30') },
      ]
    },
  },
  methods: {
    addSortCondition() {
      const condition = { ...SortAddConditionPopupStore.getters.getCondition }

      let type = 'field'
      switch (condition.field) {
        case SortConditionPopularFieldType.VIEW:
          type = 'mostViewed'
          break
        case SortConditionPopularFieldType.CLICK:
          type = 'bestClicking'
          break
        case SortConditionPopularFieldType.PURCHASE:
          type = 'bestSelling'
          break
        default:
          type = 'field'
      }
      condition.type = type
      condition.fieldType = 'number'
      condition.field = `aggregations.${condition.field}.P${this.gradation}D`

      SortGroupAddStore.commit('addCondition', condition)
      SortAddConditionPopupStore.commit('hidePopups')
    },
    getValidationState(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : null
    },
  },
})
