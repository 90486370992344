






































import Vue, { PropType } from 'vue'
import { ArrowDownIcon, ArrowUpIcon, Trash2Icon } from 'vue-feather-icons'
import SortGroupAddStore, { SortCondition, SortConditionFieldType } from '@/store/sort/sortGroupAdd'

export default Vue.extend({
  components: {
    ArrowUpIcon, ArrowDownIcon, Trash2Icon,
  },
  props: {
    conditionIndex: {
      type: Number,
      required: true,
    },
    sortCondition: {
      type: Object as PropType<SortCondition>,
      required: true,
    },
  },
  methods: {
    moveConditionDown(position: number) {
      SortGroupAddStore.commit('moveDown', position)
    },
    moveConditionUp(position: number) {
      SortGroupAddStore.commit('moveUp', position)
    },
    deleteCondition(position: number) {
      SortGroupAddStore.commit('deleteFromPosition', position)
    },
    getReadableSortConditionType(type: string) {
      switch (type) {
        case SortConditionFieldType.DEFAULT:
          return this.$t('sortSettings.addSortGroup.add.sortType.default')
        case SortConditionFieldType.FIELD:
          return this.$t('sortSettings.addSortGroup.add.sortType.field')
        case SortConditionFieldType.POPULAR:
          return this.$t('sortSettings.addSortGroup.add.sortType.popular')
        case SortConditionFieldType.SCRIPT:
          return this.$t('sortSettings.addSortGroup.add.sortType.script')
        default:
          return type
      }
    },
    getReadableSortConditionOrder(order: string) {
      return this.$t(`sortSettings.addSortGroup.order.${order}`)
    },
  },
})
