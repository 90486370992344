























































import Vue from 'vue'
import SortAddConditionPopupStore from '@/store/sort/sortAddConditionPopup'
import SortGroupAddStore, { SortConditionFieldType } from '@/store/sort/sortGroupAdd'
import SortStore from '@/store/sort/sort'
import SearchInstanceStore from '@/store/search/searchInstance'

export default Vue.extend({
  props: {
    sortOrderOptions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    addAdvancedPopupOpen: {
      get(): boolean {
        return SortAddConditionPopupStore.state.addAdvancedPopupOpen
      },
      set(value: boolean) {
        SortAddConditionPopupStore.commit('setAddAdvancedPopupOpen', value)
      },
    },
    conditionAdditionalInfo: {
      get(): string {
        return SortAddConditionPopupStore.state.condition.additionalInfo
      },
      set(field: string) {
        SortAddConditionPopupStore.commit('setConditionAdditionalInfo', field)
      },
    },
    conditionOrder: {
      get(): string {
        return SortAddConditionPopupStore.state.condition.order
      },
      set(order: string) {
        SortAddConditionPopupStore.commit('setConditionOrder', order)
      },
    },
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
    getAvailableFields() {
      let { availableFields } = SortStore.state
      availableFields = availableFields.map((item: string) => ({
        key: item,
        value: item,
        text: this.getFieldTranslation(item),
      }))
      return availableFields
    },
  },
  methods: {
    addAdvancedSortCondition() {
      const condition = { ...SortAddConditionPopupStore.getters.getCondition }
      condition.type = SortConditionFieldType.SCRIPT
      condition.field = '_script'
      SortGroupAddStore.commit('addCondition', condition)
      SortAddConditionPopupStore.commit('hidePopups')
    },
    getValidationState(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : null
    },
    getFieldTranslation(field: string) {
      return this.$te(`weightsSettings.fields.${field}`) ? this.$t(`weightsSettings.fields.${field}`) : field
    },
  },
})
