import Vue from 'vue'
import Vuex from 'vuex'
import { SortCondition } from '@/store/sort/sortGroupAdd'

Vue.use(Vuex)

interface AddConditionPopupState {
  addAdvancedPopupOpen: boolean;
  condition: SortCondition,
  addFieldBasedPopupOpen: boolean,
  addTrackingBasedPopupOpen: boolean,
}

const emptyCondition: SortCondition = {
  type: '',
  field: '',
  fieldType: '',
  order: 'desc',
  additionalInfo: '',
}
const getInitialState = (): AddConditionPopupState => ({
  condition: emptyCondition,
  addFieldBasedPopupOpen: false,
  addTrackingBasedPopupOpen: false,
  addAdvancedPopupOpen: false,
})

export default new Vuex.Store({
  state: getInitialState(),
  getters: {
    getCondition(state: any) {
      return state.condition
    },
  },
  mutations: {
    setConditionField(state: AddConditionPopupState, field: string) {
      state.condition.field = field
    },
    setConditionOrder(state: AddConditionPopupState, order: string) {
      state.condition.order = order
    },
    setConditionAdditionalInfo(state: AddConditionPopupState, additionalInfo: string) {
      state.condition.additionalInfo = additionalInfo
    },
    setAddFieldBasedPopupOpen(state: AddConditionPopupState, open: any) {
      state.condition = {
        type: '', field: '', fieldType: '', order: 'desc', additionalInfo: '',
      }
      state.addFieldBasedPopupOpen = open
    },
    setAddTrackingBasedPopupOpen(state: AddConditionPopupState, open: any) {
      state.condition = {
        type: '', field: '', fieldType: '', order: 'desc', additionalInfo: '',
      }
      state.addTrackingBasedPopupOpen = open
    },
    setAddAdvancedPopupOpen(state: AddConditionPopupState, open: any) {
      state.condition = {
        type: '', field: '', fieldType: '', order: 'desc', additionalInfo: '',
      }
      state.addAdvancedPopupOpen = open
    },
    hidePopups(state: AddConditionPopupState) {
      state.addFieldBasedPopupOpen = false
      state.addTrackingBasedPopupOpen = false
    },
  },
})
