


























































import Vue from 'vue'
import SortAddConditionPopupStore from '@/store/sort/sortAddConditionPopup'
import SortGroupAddStore from '@/store/sort/sortGroupAdd'
import SortStore from '@/store/sort/sort'
import SearchInstanceStore from '@/store/search/searchInstance'

export default Vue.extend({
  props: {
    sortOrderOptions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    addFieldBasedPopupOpen: {
      get(): boolean {
        return SortAddConditionPopupStore.state.addFieldBasedPopupOpen
      },
      set(value: boolean) {
        SortAddConditionPopupStore.commit('setAddFieldBasedPopupOpen', value)
      },
    },
    conditionField: {
      get(): string {
        return SortAddConditionPopupStore.state.condition.field
      },
      set(field: string) {
        SortAddConditionPopupStore.commit('setConditionField', field)
      },
    },
    conditionOrder: {
      get(): string {
        return SortAddConditionPopupStore.state.condition.order
      },
      set(order: string) {
        SortAddConditionPopupStore.commit('setConditionOrder', order)
      },
    },
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
    getAvailableFields() {
      let { availableFields } = SortStore.state
      availableFields = availableFields.map((item: string) => ({
        key: item,
        value: item,
        text: this.getFieldTranslation(item),
      }))
      return availableFields
    },
  },
  methods: {
    addSortCondition() {
      const condition = { ...SortAddConditionPopupStore.getters.getCondition }
      condition.type = 'field'
      const mapping = SortStore.state.availableFieldsFull.find(
        (field: { path: string, fieldType: string }) => field.path === condition.field,
      )
      condition.fieldType = mapping.fieldType
      SortGroupAddStore.commit('addCondition', condition)
      SortAddConditionPopupStore.commit('hidePopups')
    },
    getValidationState(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : null
    },
    getFieldTranslation(field: string) {
      return this.$te(`weightsSettings.fields.${field}`) ? this.$t(`weightsSettings.fields.${field}`) : field
    },
  },
})
