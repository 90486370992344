import Vue from 'vue'
import Vuex from 'vuex'

import i18n from '@/libs/i18n'
import { SearchApiRequest } from '@/store/search/searchApiRequestHelper'
import { AvailableFields } from '@/store/search/AvailableFields'
import Notifications from '@/store/notifications/notifications'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sort: {
      serp: [],
      bestseller: [],
      popular: [],
    },
    emptySort: {},
    availableFields: [],
    availableFieldsFull: [],
    searchId: '',
  },
  mutations: {
    setSortData(state: any, data: any) {
      const assignData = { ...data }
      if (!assignData.serp.length) {
        assignData.serp = []
      }
      if (!assignData.bestseller.length) {
        assignData.bestseller = []
      }
      if (!assignData.popular.length) {
        assignData.popular = []
      }
      state.sort = assignData
    },
    setAvailableFields(state: any, data: any) {
      state.availableFields = data
    },
    setAvailableFieldsFull(state: any, data: any) {
      state.availableFieldsFull = data
    },
    setSearchId(state: any, searchId: any) {
      state.searchId = searchId
    },
  },
  actions: {
    getData(context: any, searchId: string) {
      SearchApiRequest.getAvailableFields(searchId).then((result: any) => {
        context.commit('setAvailableFieldsFull', result.data)
        context.commit('setAvailableFields', AvailableFields.getAvailableFieldsFromResponse(result.data))
        context.dispatch('getSortData', searchId)
      })
    },
    getSortData(context: any, searchId: string) {
      return SearchApiRequest.getSorts(searchId).then((sortResponse: any) => {
        context.commit('setSortData', sortResponse.data)
        context.commit('setSearchId', searchId)
        return sortResponse.data
      }).catch(error => {
        Notifications.commit('addError', error, { root: true })
      })
    },
    async storeSort(context: any, data: any) {
      SearchApiRequest.setSort({
        name: data.name,
        searchId: context.state.searchId,
        sorts: data.data,
      }).then(() => {
        Notifications.commit('addPopupInfo', {
          name: i18n.t('search.blockedPhrase.insertSuccessfulNotificationTitle'),
          message: i18n.t('search.blockedPhrase.insertSuccessfulNotification'),
        }, { root: true })
      }).catch(error => {
        Notifications.commit('addPopupError', error, { root: true })
      })
    },
  },
})
