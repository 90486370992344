import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// eslint-disable-next-line no-shadow
export enum SortConditionFieldType {
  DEFAULT ='default',
  FIELD = 'field',
  SCRIPT = 'script',
  POPULAR ='popular',
}

// eslint-disable-next-line no-shadow
export enum SortConditionPopularFieldType {
  VIEW ='view',
  CLICK = 'click',
  PURCHASE = 'purchase',
}

export interface SortCondition {
  type: string,
  field: string,
  fieldType: string,
  order: string,
  additionalInfo: string,
}

export interface SortGroup {
  id: string,
  name: string,
  order: string,
  description: string,
  conditions: SortCondition[]
}

const getInitialState = (): SortGroup => ({
  id: '',
  name: '',
  order: '',
  description: '',
  conditions: [
    {
      type: SortConditionFieldType.DEFAULT,
      field: '_score',
      fieldType: '',
      order: 'desc',
      additionalInfo: '',
    },
  ],
})

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setSortId(state: SortGroup, sortId: string) {
      state.id = sortId
    },
    setName(state: SortGroup, name: string) {
      state.name = name
    },
    setOrder(state: SortGroup, order: string) {
      state.order = order
    },
    setDescription(state: SortGroup, description: string) {
      state.description = description
    },
    moveUp(state: SortGroup, position: number) {
      if (position === 0) {
        return
      }
      const { conditions } = state
      conditions.splice(position - 1, 0, conditions.splice(position, 1)[0])
      state.conditions = conditions
    },
    moveDown(state: SortGroup, position: number) {
      const { conditions } = state

      if (position === conditions.length) {
        return
      }
      conditions.splice(position + 1, 0, conditions.splice(position, 1)[0])
      state.conditions = conditions
    },
    deleteFromPosition(state: SortGroup, position: number) {
      const { conditions } = state
      conditions.splice(position, 1)
      state.conditions = conditions
    },
    addCondition(state: SortGroup, condition: SortCondition) {
      const { conditions } = state
      conditions.push(condition)
      state.conditions = conditions
    },
  },
})
