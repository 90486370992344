






































































































import Vue from 'vue'
import SortGroupAddStore from '@/store/sort/sortGroupAdd'
import SortConditionListElement from '@/views/components/sort/SortConditionListElement.vue'
import SortConditionAddElement from '@/views/components/sort/SortConditionAddElement.vue'
import { SearchApiRequest } from '@/store/search/searchApiRequestHelper'
import SearchInstanceStore from '@/store/search/searchInstance'

export default Vue.extend({
  components: { SortConditionAddElement, SortConditionListElement },
  computed: {
    sortId: {
      get(): string {
        return SortGroupAddStore.state.id
      },
      set(sortId: string) {
        SortGroupAddStore.commit('setSortId', sortId)
      },
    },
    sortName: {
      get(): string {
        return SortGroupAddStore.state.name
      },
      set(sortName: string) {
        SortGroupAddStore.commit('setName', sortName)
      },
    },
    sortDescription: {
      get(): string {
        return SortGroupAddStore.state.description
      },
      set(sortDescription: string) {
        SortGroupAddStore.commit('setDescription', sortDescription)
      },
    },
    sortConditions: {
      get(): any {
        return SortGroupAddStore.state.conditions
      },
    },
  },
  methods: {
    isDanger(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? !validation.valid : false
    },
    isSuccess(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : false
    },
    getValidationState(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : null
    },
    storeSortGroup() {
      (this.$refs.validationSortSetting as any).validate().then((success: boolean) => {
        console.log(success)
        if (!success) {
          return false
        }
        SearchApiRequest.storeSortGroup(
          SearchInstanceStore.state.searchInstanceId,
          SortGroupAddStore.state,
        ).then(() => {
          this.$router.push({ name: 'searchSortSettings' })
        })
        return true
      })
    },
  },
})
