



































import Vue from 'vue'

import SortGroupAddStore from '@/store/sort/sortGroupAdd'
import SortAddConditionPopupStore from '@/store/sort/sortAddConditionPopup'
import SortStore from '@/store/sort/sort'
import SearchInstanceStore from '@/store/search/searchInstance'
import AddSortConditionBasedOnField from '@/views/components/sort/AddSortConditionBasedOnField.vue'
import AddSortConditionAdvanced from '@/views/components/sort/AddSortConditionAdvanced.vue'
import AddSortConditionBasedOnPopularity from '@/views/components/sort/AddSortConditionBasedOnPopularity.vue'

export default Vue.extend({
  components: { AddSortConditionBasedOnPopularity, AddSortConditionAdvanced, AddSortConditionBasedOnField },
  computed: {
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
    getSortOrderOptions() {
      return [
        { value: 'asc', text: this.$t('sortSettings.addSortGroup.order.asc') },
        { value: 'desc', text: this.$t('sortSettings.addSortGroup.order.desc') },
      ]
    },
  },
  watch: {
    getSearchId() {
      this.getData()
    },
  },
  mounted() {
    if (this.getSearchId !== '') {
      this.getData()
    }
  },
  methods: {

    getData() {
      return SortStore.dispatch('getData', this.getSearchId)
    },
    addDefaultSortGroupCondition() {
      const condition = {
        type: 'default',
        field: '_score',
        order: 'desc',
        additionalInfo: 'ustawia wyniki według oceny silnika wyszukiwania',
      }
      SortGroupAddStore.commit('addCondition', condition)
    },
    openAddFieldBasedPopup() {
      SortAddConditionPopupStore.commit('setAddFieldBasedPopupOpen', true)
    },
    openAddTrackingBasedPopup() {
      SortAddConditionPopupStore.commit('setAddTrackingBasedPopupOpen', true)
    },
    openAddAdvancedPopup() {
      SortAddConditionPopupStore.commit('setAddAdvancedPopupOpen', true)
    },
  },
})
